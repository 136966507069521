import cn from 'classnames';
import styles from './Modal.module.scss';
import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { CloseButton } from '../CloseButton';

type ModalProps = {
  opened: boolean;
  closeFn: () => void;
  className?: string;
  children: ReactNode;
  hiddenButton?: boolean;
};

export function Modal({
  className,
  opened,
  closeFn,
  children,
  hiddenButton,
}: ModalProps) {
  const wrapperClassName = cn(styles.wrapper, className);
  const [rootElement, setRootElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      setRootElement(body);
    }

    if (opened && body) {
      body.classList.add('modal-opened');
    } else if (!opened && body) {
      body.classList.remove('modal-opened');
    }

    return () => {
      if (body) {
        body.classList.remove('modal-opened');
      }
    };
  }, [opened]);

  if (!rootElement) {
    return null;
  }

  if (opened) {
    return createPortal(
      <div className={wrapperClassName}>
        {!hiddenButton && (
          <CloseButton onClick={closeFn} className={styles.close} />
        )}
        <div className={styles.content}>{children}</div>
      </div>,
      rootElement,
    );
  }

  return null;
}
