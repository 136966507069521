/* eslint-disable no-console */
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { BACKEND_URL } from '@/constants';
import { FETCH_METHODS } from '@/types';

type FetchParams = {
  url: string;
  method?: FETCH_METHODS;
  body?: Record<string, unknown>;
};

export async function fetchData({
  url,
  method = FETCH_METHODS.GET,
  body,
}: FetchParams) {
  try {
    const fetchUrl = `${BACKEND_URL}${url}`;
    const token = localStorage.getItem('botai_access_token');

    const options: AxiosRequestConfig = {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    if (body) {
      options.data = body;
    }

    const response = await axios(fetchUrl, options);

    return response;
  } catch (error) {
    console.error('An error occurred while fetching data');
    if (error instanceof AxiosError) {
      return error.response;
    }
  }
}
