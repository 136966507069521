import * as React from 'react';
import styles from './CloseButton.module.scss';
import cn from 'classnames';

type CloseButtonProps = {
  onClick: () => void;
  className?: string;
  variant?: 'ghost' | 'filled';
};

export const CloseButton = ({
  onClick,
  className,
  variant,
}: CloseButtonProps) => {
  const wrapperClassName = cn(styles.wrapper, className);

  if (variant === 'ghost') {
    return (
      <img
        src="/svg/close.svg"
        alt="close"
        onClick={onClick}
        className={className}
      />
    );
  }

  return (
    <div className={wrapperClassName} onClick={onClick}>
      <img src="/svg/closeWhite.svg" alt="close" />
    </div>
  );
};
